// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-shared-single-page-template-tsx": () => import("./../../../src/components/shared/single-page-template.tsx" /* webpackChunkName: "component---src-components-shared-single-page-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nabidka-tsx": () => import("./../../../src/pages/nabidka.tsx" /* webpackChunkName: "component---src-pages-nabidka-tsx" */),
  "component---src-pages-pujcovna-tsx": () => import("./../../../src/pages/pujcovna.tsx" /* webpackChunkName: "component---src-pages-pujcovna-tsx" */),
  "component---src-pages-servis-tsx": () => import("./../../../src/pages/servis.tsx" /* webpackChunkName: "component---src-pages-servis-tsx" */)
}

